<template>
  <v-container grid-list-xl>
    <validation-observer ref="obs" v-slot="{ invalid }">
      <v-form @submit.prevent="submit" v-model="valid">
        <v-row class="fill-height">
          <v-col v-if="noPermissions === true">
            <v-btn
              color="primary"
              :to="`/site/${selectedSite.siteId}/control_permissions/create`"
            >
              <v-icon left>mdi-plus-thick</v-icon>Add Control Permissions
            </v-btn>
          </v-col>
          <v-col v-else
            ><p class="headline pt-3">Edit Control Permission</p></v-col
          >

          <v-spacer></v-spacer>
          <v-col cols="auto" md="6" sm="6">
            <v-autocomplete
              :items="sites"
              :item-text="(item) => item.name"
              v-model="selectedSite"
              label="Select site"
              return-object
              @change="handleGetControlPermissions"
              solo
            ></v-autocomplete>
          </v-col>
        </v-row>

        <confirm-dialog
          ref="confirmDelete"
          title="Delete Control Permission?"
        ></confirm-dialog>

        <!-- <v-sheet elevation="2" class="pa-4"> -->
        <v-row v-if="noPermissions === true">
          <v-col>
            <v-card
              class="card-center"
              flat
              tile
              style="background-color: rgb(250, 250, 250)"
            >
              No control Permissions configured
            </v-card></v-col
          >
        </v-row>
        <!-- </v-sheet> -->

        <v-sheet elevation="2" class="pa-4" v-if="noPermissions === false">
          <v-row class="ml-1">
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-tooltip bottom>
                <template
                  v-slot:activator="{ on, attrs }"
                  v-if="$can('delete', 'meters')"
                >
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                    @click="handleControlPermissionDelete"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete Control Permission</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="6">
              <validated-text-field
                v-model="currentPermission.account"
                label="Account String"
                hint="account string used for api url configured by provicer"
                persistent-hint
                rules="required"
                ref="accountText"
              ></validated-text-field
            ></v-col>
            <v-col sm="6">
              <v-checkbox
                v-model="currentPermission.isIgnored"
                color="primary"
                label="Ignore"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn type="submit" color="primary" :disabled="invalid"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-sheet>

        <v-sheet elevation="4" class="pa-4 mt-2" v-if="noPermissions === false">
          <v-tabs right v-model="active_tab">
            <v-tab :key="loginTab">Logins</v-tab>
            <v-tab-item :key="loginTab">
              <login
                :logins="currentPermission.logins"
                :siteId="siteId"
                @createlogin="createLogin"
                @deletelogin="deleteLogin"
                @updatelogin="updateLogin"
              ></login>
            </v-tab-item>
          </v-tabs>
        </v-sheet>
        <v-row justify="center">
          <v-dialog v-model="validateDialog" max-width="600">
            <v-card>
              <v-card-title class="headline"
                >Validating Virtual Monitor</v-card-title
              >
              <v-card-text>
                <v-stepper v-model="vSteps" vertical>
                  <!--Validate Name Step-->
                  <v-stepper-step
                    :complete="vSteps > 1 && isValidPermissionAccount"
                    step="1"
                    color="green"
                  >
                    Validating Account String
                    <small
                      v-if="vSteps > 1 && isValidPermissionAccount === true"
                      >{{ "Account String is valid" }}</small
                    >
                    <v-row
                      style="width: 500px"
                      v-if="vSteps > 1 && isValidPermissionAccount === false"
                    >
                      <v-col class="pt-0" cols="2" md="9" sm="9">
                        <small
                          v-if="
                            vSteps > 2 && isValidPermissionAccount === false
                          "
                          >{{ "You have a duplicate Account String" }}</small
                        >
                      </v-col>
                      <v-col cols="auto" md="3" sm="3">
                        <v-btn
                          class="mt-n7"
                          color="primary"
                          title="You have a duplicate Equipment name"
                          @click="fixPermissionErrors(0)"
                          >Fix</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-stepper-step>
                </v-stepper>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="green darken-1" text @click="cancelDialog"
                  >Cancel</v-btn
                >
                <v-btn
                  color="green darken-1"
                  :disabled="!isValidPermission || invalid"
                  text
                  @click="dismissDialog"
                  >Continue</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-form></validation-observer
    >
  </v-container>
</template>
<style>
.card-center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  height: 25vh;
  width: 50%;
  margin-left: 25%;
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";
import goTo from "vuetify/es5/services/goto";
import { mixin as VueTimers } from "vue-timers";
import { ValidationObserver } from "vee-validate";
import api from "../_api";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import ValidatedTextField from "@/components/Fields/ValidatedTextField";
// import ValidatedSelectField from "@/components/Fields/ValidatedSelectField";
import Login from "./Login";

export default {
  components: {
    "validated-text-field": ValidatedTextField,
    // "validated-select-field": ValidatedSelectField,
    "validation-observer": ValidationObserver,
    "confirm-dialog": ConfirmDialog,
    login: Login,
  },

  timers: {
    metaValidator: { time: 1000, autostart: false, repeat: true },
  },

  mixins: [VueTimers],

  data() {
    return {
      valid: true,
      currentPermission: {},
      noPermission: true,
      currentAccount: null,
      isValidating: false,
      vSteps: 0,
      isValidPermission: true,
      isValidPermissionAccount: false,
      dialog: false,
      validateDialog: false,
      siteId: null,
      sites: [],
      loginTypes: [],
      loginTab: null,
      active_tab: 0,
      selectedSite: {},
      selectedLoginType: null,
    };
  },

  async beforeCreate() {
    try {
      this.sites = await api.getSites();

      if (this.defaultSite !== null) {
        this.selectedSite = this.defaultSite;
        this.siteId = this.selectedSite.siteId;
        this.currentPermission = await api.getControlPermissionBySiteId(
          this.siteId
        );
        if (this.currentPermission) {
          this.currentAccount = this.currentPermission.account;
        }
      }
    } catch (error) {
      console.log(error);
    }
  },

  computed: {
    ...mapGetters({
      defaultSite: "session/defaultSite",
    }),
    noPermissions: function () {
      return this.currentAccount === null;
    },
  },

  methods: {
    ...mapActions({
      setDefaultSite: "session/setDefaultSite",
    }),

    async submit() {
      let valid = await this.$refs.obs.validate();
      if (valid === false) {
        return;
      }

      this.isValidating = false;
      this.validatePermission();
    },

    handleLogin() {},

    async handleGetControlPermissions(selectedSite) {
      this.setDefaultSite(selectedSite);
      this.siteId = this.selectedSite.siteId;
      this.clearCurrentPermission();
      this.currentPermission = await api.getControlPermissionBySiteId(
        this.siteId
      );
      if (this.currentPermission) {
        this.currentAccount = this.currentPermission.account;
      }
    },

    async refreshControlPermissions() {
      this.clearCurrentPermission();
      this.currentPermission = await api.getControlPermissionBySiteId(
        this.siteId
      );
      if (this.currentPermission) {
        this.currentAccount = this.currentPermission.account;
      }
    },

    async handleControlPermissionDelete() {
      const msg = `Are you sure you want to delete this control permission and its logins?  This cannot be undone.`;
      const confirmed = await this.$refs.confirmDelete.confirm(msg);
      if (confirmed === false) {
        return;
      }
      let deletePermission = {
        siteId: this.siteId,
        id: this.currentPermission.id,
      };
      await api.deleteControlPermission(deletePermission);
      await this.refreshControlPermissions();
    },

    // forceUpdate() {
    //   window.location.reload();
    // },

    clearCurrentPermission() {
      this.currentAccount = null;
      this.currentPermission = {};
    },

    async createLogin(login) {
      login.controlPermissionId = this.currentPermission.id;
      await api.createLogin(login);
      this.currentPermission = await api.getControlPermissionBySiteId(
        this.siteId
      );
    },

    async deleteLogin(login) {
      await api.deleteLogin(login, this.currentPermission.id);
      this.currentPermission = await api.getControlPermissionBySiteId(
        this.siteId
      );
    },

    async updateLogin(login) {
      login.controlPermissionId = this.currentPermission.id;
      await api.updateLogin(login);
      this.currentPermission = await api.getControlPermissionBySiteId(
        this.siteId
      );
    },

    validatePermission() {
      this.isValidating = true;
      this.isValidPermissionAccount = false;
      this.isValidFriendlyName = false;
      this.validateDialog = true;
      this.$timer.start("metaValidator");
    },

    fixPermissionErrors(item) {
      this.cancelDialog();
      this.isValidPermission = true;
      console.log(`fixing validation item ${item}`);
      switch (item) {
        case 0:
          this.$nextTick().then(() => {
            this.focusAccount();
          });

          break;

        default:
          break;
      }
    },

    focusAccount() {
      this.$refs.accountText.focus();
    },

    cancelDialog() {
      this.isValidating = false;
      this.validateDialog = false;
      this.$timer.stop("metaValidator");
      this.vSteps = 0;
    },

    async dismissDialog() {
      this.cancelDialog();
      if (this.isValidPermission) {
        goTo(0); //scroll up
        this.loading = true;
        try {
          await api.updateControlPermission(this.currentPermission);
          await this.refreshControlPermissions();
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      }
    },

    metaValidator() {
      this.vSteps++;

      if (this.vSteps == 1) {
        // compare proposed name to cached list of permissions for site
        this.isValidPermissionAccount = true;
      }

      if (this.vSteps > 1) {
        this.$timer.stop("metaValidator");
        this.isValidating = false;
      }

      this.isValidPermission = this.isValidPermissionAccount;

      console.log(`validPermission: ${this.isValidPermission}`);
    },
  },
};
</script>