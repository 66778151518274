<template>
  <validation-observer ref="logins" v-slot="{ invalid }">
    <confirm-dialog ref="confirmDelete" title="Delete Login?"></confirm-dialog>
    <v-data-table
      :headers="loginHeaders"
      :items="currentLogins"
      class="elevation-1"
      :loading="loginsLoading"
      :loading-text="`Loading... One moment`"
    >
      <template v-slot:top>
        <v-dialog v-model="loginDialog" max-width="75vw">
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-on="on"
              @click="handleLoginCreate"
            >
              New Login
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ loginAction }} Login</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="4" md="4">
                    <validated-text-field
                      v-model="login.userName"
                      label="User Name"
                      rules="required"
                    ></validated-text-field>
                  </v-col>
                  <!--
                  <v-col cols="12" sm="4" md="4">
                    <validated-text-field
                      v-model="login.password"
                      label="Password"
                      rules="required"
                    ></validated-text-field>
                  </v-col>
                  -->
                  <v-col cols="12" sm="4" md="4">
                    <validated-select-field
                      :items="loginTypes"
                      :item-text="(item) => item.name"
                      :item-value="(item) => item.id"
                      v-model="login.loginType"
                      label="Login Type"
                      rules="required"
                      @change="handleSetLoginType($event)"
                      :return-object="false"
                    ></validated-select-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn color="primary" dark @click="handlePasswordEdit">
                Update Password
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="cancelDialog">
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="handleLoginSave"
                :disabled="invalid"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      
      <template>
        <v-dialog v-model="passwordDialog" max-width="35vw">
          <v-card>
            <v-card-title>
              <span class="headline">Update Password</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <validated-text-field
                      v-model="login.password"
                      label="Password"
                    ></validated-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="cancelPasswordDialog">
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="handlePasswordSave"
                :disabled="invalid"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <td>
          <v-btn @click="handleLoginEdit(item)" icon>
            <v-icon medium>edit</v-icon>
          </v-btn>
          <v-btn icon @click="handleLoginDelete(item)">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </td>
      </template></v-data-table
    ></validation-observer
  >
</template>

<script>
import ValidatedSelectFieldVue from "@/components/Fields/ValidatedSelectField.vue";
import ValidatedTextField from "@/components/Fields/ValidatedTextField";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import { ValidationObserver } from "vee-validate";
import api from "./../_api";

export default {
  components: {
    "validated-text-field": ValidatedTextField,
    "validated-select-field": ValidatedSelectFieldVue,
    "validation-observer": ValidationObserver,
    "confirm-dialog": ConfirmDialog,
  },

  props: {
    logins: { type: Array, default: () => [] },
  },

  watch: {
    logins(newVal) {
      console.log("logins updated");
      this.currentLogins = newVal;
    },
  },

  data() {
    return {
      currentLogins: [],
      login: {
        id: null,
        userName: null,
        passord: null,
        loginType: { loginType: null, loginTypeName: null },
      },
      loginAction: "",
      loginTypes: [],
      loginDialog: false,
      loginType: null,
      loginsLoading: false,
      passwordDialog: false,
      loginHeaders: [
        { text: "User Name", value: "userName" },
        /*
        {
          text: "Password",
          value: "password",
        },
        */
        { text: "Login Type", value: "loginTypeName" },
        { text: "Actions", value: "id", sortable: false },
      ],
    };
  },

  async created() {
    this.currentLogins = this.logins;
    this.loginTypes = await api.getControlPermissionLoginTypes();
  },

  methods: {
    async handleLoginSave() {
      if (this.loginAction === "Add") {
        await this.createLogin();
      } else {
        this.updateLogin();
      }
    },

    async handlePasswordSave() {
      this.passwordDialog = false;
    },


    async createLogin() {
      let valid = await this.$refs.logins.validate();
      if (valid === false) {
        return;
      }

      this.$emit("createlogin", this.login);
      this.loginDialog = false;
    },

    async updateLogin() {
      let valid = await this.$refs.logins.validate();
      if (valid === false) {
        return;
      }

      this.$emit("updatelogin", this.login);
      this.loginDialog = false;
    },

    async deleteLogin(Login) {
      this.$emit("deletelogin", Login);
      this.loginDialog = false;
    },

    cancelDialog() {
      this.loginDialog = false;
    },

    cancelPasswordDialog() {
      this.passwordDialog = false;
    },

    handleSetLoginType(loginType) {
      console.log(loginType);
    },

    async handleLoginCreate() {
      await this.$refs.logins.reset();
      this.loginAction = "Add";
      this.login = {};
    },

    handleLoginEdit(login) {
      this.loginAction = "Edit";
      this.login = { ...login };
      this.login.loginType = login.loginTypeId
      this.loginDialog = true;
      console.log(login);
    },

    handlePasswordEdit() {
      this.loginAction = "Edit";
      this.login.password = "";
      this.passwordDialog = true;
    },

    async handleLoginDelete(login) {
      console.log(login);
      const msg = `Are you sure you want to delete ${login.userName} ${login.loginTypeName} login?`;
      const confirmed = await this.$refs.confirmDelete.confirm(msg);

      if (!confirmed) {
        return;
      }
      this.deleteLogin(login);
    },
  },
};
</script>